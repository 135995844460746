import { useEffect, useState } from 'react';
import { ContentDao } from '../../model/dao/ContentDao';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setSquareRandomContentAction } from '../../model/store/slices/SpecSlice';
import { ActiveGameController } from '../../control/play/ActiveGameController';
import { GameObserver } from '../../control/play/GameObserver';
import { all } from 'axios';
import { enqueueSnackbar } from 'notistack';
import { GuiService } from '../../services/GuiService';

const useContentHook = (square, allContents = [], shownContents = []) => {
  const dispatch = useDispatch();
  const { id, order, type, coords, randomContent, content } = square ?? {};
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fetched, setFetched] = useState(false);

  useEffect(() => {
    console.debug(`@UCH: square set to : ${square?.id}`);
    if (!square) {
      setFetched(false);
      setLoading(false);
      setError(false);
    }
  }, [square]);

  useEffect(() => {
    const fetchContent = async () => {
      try {
        let randomContentId = null;
        const availableContents = allContents[type].filter((cId) => !shownContents[type].includes(cId));
        if (availableContents.length > 0) {
          randomContentId = availableContents[Math.floor(Math.random() * availableContents.length)];
        } else {
          console.debug(`@UCH: contents out of stock for type: ${type}`);
          ActiveGameController.submitPlayerResponse(null, square);
          setFetched(true);
          return;
        }
        setLoading(true);
        setError(null);
        console.debug(`@UCH: picked randomcontentId: ${randomContentId}`);
        const rContent = await ContentDao.fetchContent(randomContentId);
        const squareCoordsKey = `${coords?.row}_${coords?.col}`;
        dispatch(setSquareRandomContentAction({ squareNum: order, squareCoordsKey, content: rContent }));
        console.debug(`@UCH: square content set to: ${randomContentId}`);
        GameObserver.setSquareContent(order, squareCoordsKey, rContent);
        setFetched(true);
      } catch (error) {
        setError(error?.message);
      } finally {
        setLoading(false);
      }
    };
    const isActing = ActiveGameController.isPrimaryActor();
    if (isActing && randomContent && !loading && !fetched) {
      fetchContent();
    } else if (!randomContent) {
      console.debug(`@UCH: Square ${square?.id} has fixed content: ${square?.contentId}`);
    }
  }, [allContents, coords, dispatch, fetched, loading, order, randomContent, type, shownContents, square]);

  return { content, error };
};

export default useContentHook;
