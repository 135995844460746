import { ContentType, SquareType } from '@grethics/commons';
import { Check, Close, Delete, Edit } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, FormControl, FormControlLabel, FormHelperText, IconButton, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React, { useImperativeHandle, useRef, useState } from 'react';
import { Controller, useForm, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import DisposableDialogTitle from '../common/DisposableDialogTitle';
import GameAvatar from '../common/GameAvatar';
import MediaLibraryModal from './MediaLibraryModal';
import ReactQuill from 'react-quill';
import { RTEditorToolbarOptions } from '../../../utils/Utils';
import MediaViewer from '../play/MediaViewer';

const ContentForm = ({ gameId }) => {
  const mediaLibRef = useRef();
  const { control, setValue } = useFormContext();
  return (
    <div className='flex flex-col lg:flex-row flex-1 lg:items-start md:items-center gap-4'>
      <div className='flex-grow'>
        <Controller
          name={`body.text`}
          control={control}
          render={({ field, fieldState: { error } }) => {
            return (
              <FormControl error={!!error} fullWidth>
                <InputLabel sx={{ top: -25, bgcolor: 'white', fontSize: 12, px: 1 }}>Text</InputLabel>
                <ReactQuill
                  theme='snow'
                  className='border rounded p-3'
                  modules={{ toolbar: RTEditorToolbarOptions }}
                  value={field.value}
                  onChange={(v) => {
                    if (v !== '<p><br></p>') {
                      setValue(field.name, v, { shouldTouch: true, shouldDirty: true, shouldValidate: true });
                    }
                  }}
                />
                <FormHelperText>{error?.message}</FormHelperText>
              </FormControl>
            );
          }}
        />
      </div>
      <div className='flex flex-grow-0 min-w-[250px]'>
        <Controller
          name={`body.mediaId`}
          control={control}
          //rules={{ required: 'Background image is required' }}
          render={({ field, fieldState: { error } }) => (
            <FormControl error={!!error}>
              <div className='px-2 flex flex-col border rounded-md border-gray-300 items-center'>
                <InputLabel sx={{ top: -25, bgcolor: 'white', fontSize: 12, pr: 1 }}>Media File</InputLabel>
                <div className='my-3 top-0 z-0 w-max-[250px]'>
                  <MediaViewer mediaId={field.value} width={'100%'} height={'100%'} mediaStyle={{ maxWidth: 300, margin: 5, backgroundColor: 'lightgray', aspectRatio: '16/9' }} />
                </div>
                <div className='flex flex-row w-full justify-between pt-1 absolute top-1 px-3'>
                  <IconButton
                    onClick={() => {
                      mediaLibRef.current?.show(gameId);
                    }}
                    sx={{ borderRadius: 1, py: 1, px: 0 }}
                    color='primary'
                    className='z-10'>
                    <Edit />
                  </IconButton>
                  {field.value && (
                    <IconButton
                      onClick={() => {
                        setValue(field.name, undefined, { shouldTouch: true, shouldDirty: true, shouldValidate: true });
                      }}
                      sx={{ borderRadius: 1, py: 1, px: 0 }}
                      color='error'
                      className='z-10'>
                      <Delete />
                    </IconButton>
                  )}
                </div>
              </div>
              <FormHelperText>{error?.message}</FormHelperText>
              <div>
                <MediaLibraryModal
                  ref={mediaLibRef}
                  mediaId={field.value}
                  mediaTypes={['image', 'video', 'audio']}
                  onSelect={(mediaId) => {
                    setValue(field.name, mediaId, { shouldTouch: true, shouldDirty: true, shouldValidate: true });
                  }}
                />
              </div>
            </FormControl>
          )}
        />
      </div>
    </div>
  );
};

export default ContentForm;
