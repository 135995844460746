"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MOVED_FWD = exports.MOVED_BWD = exports.SKIP_VISITED = exports.OBLIGATORY_SQUARE = exports.END_OF_PATH = exports.SOUND_FX_TYPE = exports.AGE_GROUP = exports.PLAY_STATUS = exports.GAME_STATUS = exports.SOCKET_EVENTS = exports.MODERATING_ROLES = exports.USER_ROLE = void 0;
exports.USER_ROLE = {
    PLAYER: 'PLAYER',
    MODERATOR: 'MODERATOR',
    PLAYER_MODERATOR: 'PLAYER_MODERATOR',
    AUTHOR: 'AUTHOR',
    ADMIN: 'ADMIN',
};
exports.MODERATING_ROLES = [exports.USER_ROLE.MODERATOR, exports.USER_ROLE.PLAYER_MODERATOR];
exports.SOCKET_EVENTS = {
    PLAYER_CONNECTED: 'PLAYER_CONNECTED',
    PLAYER_DISCONNECTED: 'PLAYER_DISCONNECTED',
    SET_ACTIVE_PLAYER: 'SET_ACTIVE_PLAYER',
    SET_LAST_ROLL: 'SET_LAST_ROLL',
    SET_SCORE: 'SET_SCORE',
    SET_GAME_STATUS: 'SET_GAME_STATUS',
    SET_CURRENT_SQUARE: 'SET_CURRENT_SQUARE',
    INTERACTION_COMPLETED: 'INTERACTION_COMPLETED',
    SET_PLAYER_SCORE: 'SET_PLAYER_SCORE',
    HIDE_FEEDBACK: 'HIDE_FEEDBACK',
    SET_GAME_RESULT: 'SET_GAME_RESULT',
    SET_PLAYER_ANSWER: 'SET_PLAYER_ANSWER',
    SUBMIT_PLAYER_ANSWER: 'SUBMIT_PLAYER_ANSWER',
    SET_SQUARE_CONTENT: 'SET_SQUARE_CONTENT',
    ROLL_THE_DICE: 'ROLL_THE_DICE',
    SHOW_ALERT: 'SHOW_ALERT',
    HIDE_ALERT: 'HIDE_ALERT',
};
exports.GAME_STATUS = {
    NOT_STARTED: 'NOT_STARTED',
    STARTING_UP: 'STARTING_UP',
    STARTED: 'STARTED',
    COMPLETED: 'COMPLETED',
};
exports.PLAY_STATUS = {
    WAITING: 'WAITING',
    READY_TO_PLAY: 'READY_TO_PLAY',
    IN_PLAY: 'IN_PLAY',
    DISABLED: 'DISABLED',
};
exports.AGE_GROUP = {
    PRIMARY_SCHOOL: 'PRIMARY_SCHOOL',
    LOWER_HIGH_SCHOOL: 'LOWER_HIGH_SCHOOL',
    UPPER_HIGH_SCHOOL: 'UPPER_HIGH_SCHOOL',
    ADULT: 'ADULT',
};
exports.SOUND_FX_TYPE = {
    BG: 'BG',
    WALK: 'WALK',
    DICE: 'DICE',
    WIN: 'WIN',
    TIE: 'TIE',
    FAILURE: 'FAILURE',
    SUCCESS: 'SUCCESS',
};
exports.END_OF_PATH = 'END_OF_PATH';
exports.OBLIGATORY_SQUARE = 'OBL_SQR';
exports.SKIP_VISITED = 'SKIP_VISITED';
exports.MOVED_BWD = 'MOVED_BACKWARD';
exports.MOVED_FWD = 'MOVED_FORWARD';
