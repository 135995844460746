"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserSearchMode = exports.PlayerSearchMode = exports.SquareSide = exports.TransitionType = exports.QuestionType = exports.FinalSquareTypes = exports.SquareTypes = exports.SquareType = exports.ContentTypes = exports.ContentType = void 0;
var ContentType;
(function (ContentType) {
    ContentType["FACT"] = "FACT";
    ContentType["QUIZ"] = "QUIZ";
    ContentType["STORY"] = "STORY";
    ContentType["ICON"] = "ICON";
    ContentType["DID_YOU_KNOW"] = "DID_YOU_KNOW";
})(ContentType || (exports.ContentType = ContentType = {}));
exports.ContentTypes = [ContentType.FACT, ContentType.QUIZ, ContentType.STORY, ContentType.ICON, ContentType.DID_YOU_KNOW];
var SquareType;
(function (SquareType) {
    SquareType["FACT"] = "FACT";
    SquareType["QUIZ"] = "QUIZ";
    SquareType["STORY"] = "STORY";
    SquareType["ICON"] = "ICON";
    SquareType["DID_YOU_KNOW"] = "DID_YOU_KNOW";
    SquareType["START"] = "START";
    SquareType["END"] = "END";
})(SquareType || (exports.SquareType = SquareType = {}));
exports.SquareTypes = [SquareType.FACT, SquareType.QUIZ, SquareType.STORY, SquareType.ICON, SquareType.DID_YOU_KNOW, SquareType.START, SquareType.END];
exports.FinalSquareTypes = [SquareType.START, SquareType.END];
var QuestionType;
(function (QuestionType) {
    QuestionType["OPEN"] = "OPEN";
    QuestionType["MCSS"] = "MCSS";
    QuestionType["MCMS"] = "MCMS";
})(QuestionType || (exports.QuestionType = QuestionType = {}));
var TransitionType;
(function (TransitionType) {
    TransitionType["fwd"] = "fwd";
    TransitionType["bwd"] = "bwd";
})(TransitionType || (exports.TransitionType = TransitionType = {}));
var SquareSide;
(function (SquareSide) {
    SquareSide["top"] = "top";
    SquareSide["bottom"] = "bottom";
    SquareSide["left"] = "left";
    SquareSide["right"] = "right";
    SquareSide["auto"] = "auto";
})(SquareSide || (exports.SquareSide = SquareSide = {}));
var PlayerSearchMode;
(function (PlayerSearchMode) {
    PlayerSearchMode["MIXED"] = "MIXED";
    PlayerSearchMode["REGISTERED"] = "REGISTERED";
    PlayerSearchMode["UNREGISTERED"] = "UNREGISTERED";
})(PlayerSearchMode || (exports.PlayerSearchMode = PlayerSearchMode = {}));
var UserSearchMode;
(function (UserSearchMode) {
    UserSearchMode["ADMIN"] = "ADMIN";
    UserSearchMode["AUTHOR"] = "AUTHOR";
    UserSearchMode["PLAYER"] = "PLAYER";
    UserSearchMode["ALL"] = "ALL";
})(UserSearchMode || (exports.UserSearchMode = UserSearchMode = {}));
