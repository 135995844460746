import { Navigate } from 'react-router-dom';
import ProtectedRoute from '../view/ProtectedRoute';
import RootPlayPage from '../view/screens/play/RootPlayPage';
import JoinTableForm from '../view/screens/play/JoinTableForm';
import { PlayErrorElement } from '../view/components/common/RouteErrorElements';
import PlayListBrowser from '../view/screens/play/PlayListBrowser';
import TableBrowser from '../view/screens/play/TableBrowser';
import NewTablePage from '../view/screens/play/NewTablePage';
import GamePage from '../view/screens/play/GamePage';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { PALLETE } from '../config/constants';

const playTheme = createTheme({
  typography: {
    fontFamily: '"Open Sans"',
  },
  palette: {
    neutral: { light: '#aaa', main: '#363636', dark: '#1c1c1c' },
    primary: PALLETE.EMERLAND,
    secondary: PALLETE.PURPLE,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 500,
      md: 640,
      lg: 800,
      xl: 1024,
    },
  },
});

function PlayThemeProvider({ children }) {
  return <ThemeProvider theme={playTheme}>{children}</ThemeProvider>;
}

export const PlayRoutes = [
  {
    path: 'play',
    element: (
      <ProtectedRoute>
        <PlayThemeProvider>
          <RootPlayPage />
        </PlayThemeProvider>
      </ProtectedRoute>
    ),
    children: [
      {
        path: '',
        element: <Navigate to={'join'} />,
      },
      {
        path: 'join',
        element: <JoinTableForm />,
        errorElement: <PlayErrorElement />,
      },
      {
        path: 'games',
        element: <PlayListBrowser />,
        errorElement: <PlayErrorElement />,
      },
      {
        path: 'tables',
        element: <TableBrowser />,
        errorElement: <PlayErrorElement />,
      },
      {
        path: 'new/:slug',
        element: <NewTablePage />,
      },
    ],
  },
  {
    path: 'play/:slug',
    element: (
      <ProtectedRoute>
        <GamePage />
      </ProtectedRoute>
    ),
  },
];
