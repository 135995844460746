import { TableDao } from '../../model/dao/TableDao';
import { AppStore } from '../../model/store/AppStore';
import { CustomResponseError, getSoundFxUrl } from '../../utils/Utils';
import { leaveGameAction, setGameAction, setSquareRandomContentAction } from '../../model/store/slices/SpecSlice';
import {
  setActivePlayerAction,
  startGamePlayAction,
  setCurrentSquareAction,
  setLastRollAction,
  setRollerActiveAction,
  setGameStatusAction,
  setPlayerScoreAction,
  setGameResultAction,
  setPlayerAnswerAction,
  submitPlayerAnswerAction,
  playerDisconnectedAction,
  setOnlineAppStatusAction,
  setContentShownAction,
} from '../../model/store/slices/PlaySlice';
import { isValueEmpty, MODERATING_ROLES, MOVED_BWD, MOVED_FWD, SOUND_FX_TYPE } from '@grethics/commons';
import { GameObserver } from './GameObserver';
import { getOtherSide, hasSquareBeenVisited, parseGoosePath, parsePathSections } from '../../utils/GameUtils';
import { GAME_STATUS, END_OF_PATH, OBLIGATORY_SQUARE, SKIP_VISITED } from '@grethics/commons';
import { enqueueSnackbar } from 'notistack';
import { DEFAULT_PLAYER_STATE } from '../../config/config';
import { GuiService } from '../../services/GuiService';
import { maxBy } from 'lodash';
import DefaultSuccessFx from '../../assets/sounds/success.mp3';
import DefaultFailureFx from '../../assets/sounds/failure.mp3';
import DefaultTieFx from '../../assets/sounds/tie.mp3';
import DefaultWinFx from '../../assets/sounds/win.mp3';
import PlayerAvatar from '../../view/components/play/PlayerAvatar';
import Podium from '../../assets/img/podium.svg';
import GameTie from '../../assets/img/game-tie.svg';
import { Link } from 'react-router-dom';
const avatars = { left: null, right: null };

function setAvatars(theAvatars = { left: {}, right: {} }) {
  avatars.left = theAvatars.left;
  avatars.right = theAvatars.right;
}

async function enterGame({ slug }) {
  try {
    if (!slug) {
      return;
    }
    const { table, userRole, userSide } = await TableDao.fetchFullTable(slug);
    const { gamePlayers, state: gameState, game, moderatorId } = table;
    const { board } = game;
    delete game.board;
    const boardPath = parseGoosePath(board?.path) ?? [];
    const boardSections = parsePathSections(board?.sections ?? []);
    const players = {};
    const leftGP = gamePlayers.find((gp) => gp.side === 'left');
    const rightGP = gamePlayers.find((gp) => gp.side === 'right');
    players.left = { ...leftGP.player, connected: leftGP.connected, state: leftGP.state ?? DEFAULT_PLAYER_STATE };
    players.right = { ...rightGP.player, connected: rightGP.connected, state: rightGP.state ?? DEFAULT_PLAYER_STATE };
    players.left.side = 'left';
    players.right.side = 'right';
    AppStore.dispatch(setRollerActiveAction(false));
    if (MODERATING_ROLES.includes(userRole)) {
      players.left.connected = true;
      players.right.connected = true;
      AppStore.dispatch(
        setGameAction({ tableId: table.id, tableSlug: table.slug, game, moderatorId, userRole, gameState, userSide, board: { ...board, path: boardPath, sections: boardSections }, players })
      );
      GameObserver.connect(slug, true);
    } else {
      players[userSide].connected = true;
      AppStore.dispatch(
        setGameAction({ tableId: table.id, tableSlug: table.slug, game, moderatorId, userRole, userSide, gameState, board: { ...board, path: boardPath, sections: boardSections }, players })
      );
      GameObserver.connect(slug);
    }
    AppStore.dispatch(setRollerActiveAction(true));
  } catch (error) {
    throw CustomResponseError(error);
  }
}

async function exitGame() {
  AppStore.dispatch(leaveGameAction());
  GameObserver.disconnect();
}

async function completeGame(side) {
  const {
    spec: {
      game: { id: gameId },
    },
    table: { tableId },
  } = AppStore.getState().play.current;
  AppStore.dispatch(setGameStatusAction({ gameId, tableId, status: GAME_STATUS.COMPLETED }));
  setActivePlayer('none');
  GameObserver.setGameStatus(GAME_STATUS.COMPLETED, side);
  setGameResult();
}

function isPrimaryActor() {
  const { userSide, activeSide } = AppStore.getState().play?.current?.table;
  const moderated = !!AppStore.getState().play?.current?.spec?.moderatorId;
  if (moderated) {
    return true;
  }
  const result = userSide === activeSide;
  //console.log('@GC.isPrimaryActor: ', result);
  return result;
}

function setGameResult() {
  const { players } = AppStore.getState().play?.current?.table;
  let winner = maxBy(Object.values(players), (player) => player.state.score);
  let msg;
  let soundFx;
  const { soundFxOn } = AppStore.getState().play?.current?.table;
  const { tieFx, winFx, leaderboards, slug } = AppStore.getState().play?.current.spec.game;
  const ldrbrdMsg = leaderboards ? (
    <p className='text-xl'>
      Check{' '}
      <Link className='text-blue-600' to={`../../rankings/${slug}`}>
        player rankigns
      </Link>{' '}
      to see if your achievement!
    </p>
  ) : null;

  if (players.left.state.score === players.right.state.score) {
    winner = { side: 'none' };
    msg = (
      <div className='flex flex-1 flex-col items-center'>
        <img alt='' src={GameTie} />
        <p className='text-4xl'>It's a Tie! Congratulations to both players!</p>
        {ldrbrdMsg}
      </div>
    );
    soundFx = new Audio(tieFx ?? DefaultTieFx);
  } else {
    msg = (
      <div className='flex flex-1 flex-col items-center p-2'>
        <PlayerAvatar width={100} height={100} imageId={winner.imageId} />
        <img alt='' src={Podium} />
        <p className='text-4xl'>{`${winner.name} won!`}</p>
        <p className='text-xl text-orange-600'>Congratulations!!!!</p>
        {ldrbrdMsg}
      </div>
    );
    soundFx = new Audio(winFx ?? DefaultWinFx);
  }
  GameObserver.setGameResult(winner.side);
  AppStore.dispatch(setGameResultAction({ winner }));
  if (soundFxOn) {
    soundFx.volume = 0.5;
    soundFx.play();
  }
  GuiService.showFeedback({ variant: 'success', title: 'Game Completed!', content: msg, duration: 10 });
}

function startGameUp(side) {
  const userSide = AppStore.getState().play?.current?.table?.userSide;
  const startingSide = side === 'none' ? userSide : side;
  const {
    spec: {
      game: { id: gameId },
    },
    table: { tableId },
  } = AppStore.getState().play.current;
  AppStore.dispatch(setGameStatusAction({ gameId, tableId, status: GAME_STATUS.STARTING_UP }));
  GameObserver.setGameStatus(GAME_STATUS.STARTING_UP);
  AppStore.dispatch(setActivePlayerAction({ side: startingSide }));
  GameObserver.setActivePlayer(startingSide);
  AppStore.dispatch(setRollerActiveAction(startingSide === userSide));
}

function setActivePlayer(side) {
  GameObserver.setActivePlayer(side);
  AppStore.dispatch(setActivePlayerAction({ side }));
  AppStore.dispatch(setRollerActiveAction(true));
}

function switchPlayer() {
  const { activeSide } = AppStore.getState().play?.current?.table;
  const side = getOtherSide(activeSide);
  setActivePlayer(side);
}

function startGamePlay() {
  const {
    spec: {
      game: { id: gameId },
    },
    table: { tableId },
  } = AppStore.getState().play.current;
  AppStore.dispatch(startGamePlayAction({ gameId, tableId }));
  GameObserver.startGamePlay();
}

function setPlayerCurrentSquare(side, idx) {
  AppStore.dispatch(setCurrentSquareAction({ side, idx }));
  if (isPrimaryActor()) {
    GameObserver.setPlayerCurrentSquare(side, idx);
  }
}

function getPlayerCurrentSquare(side) {
  return AppStore.getState().play?.current?.table?.players[side]?.state.curSquare ?? 0;
}

function calculatePlayerMove(side, steps) {
  const { players, visitedSquares } = AppStore.getState().play?.current?.table;
  const { path } = AppStore.getState().play?.current?.spec.board;
  const { revisitRandomSquares } = AppStore.getState().play?.current?.spec?.game;

  const player = players[side];
  let deviation = undefined;
  const remainingSteps = path.asArray.length - (player.state.curSquare + 1);
  let stepsToMove = steps > remainingSteps ? remainingSteps : steps;

  const curPos = player.state.curSquare ?? 0;
  const walkStart = curPos + 1;
  let walkEnd = curPos + stepsToMove;
  const walk = path.asArray.slice(walkStart, walkEnd);

  if (stepsToMove !== steps || remainingSteps === steps) {
    deviation = END_OF_PATH;
  }
  //1. Check for obligatory square in the walk.
  const oblSquaresInWalk = walk.filter((square) => square.obligatory);
  if (oblSquaresInWalk.length > 0) {
    oblSquaresInWalk.every((square) => {
      const alreadyVisited = hasSquareBeenVisited(visitedSquares, square?.order);
      if (!alreadyVisited || (square.randomContent && revisitRandomSquares)) {
        stepsToMove = walk.findIndex((sq) => sq.id === square.id) + 1;
        deviation = OBLIGATORY_SQUARE;
        return false;
      }
      return true;
    });    
  }

  //2. Check for already visited squares in the walk (only if dont have to stop on obligatory square and not end of path has been reached)
  if (!deviation) {
    while (!(path.asArray[walkEnd].randomContent && revisitRandomSquares) && hasSquareBeenVisited(visitedSquares, walkEnd) && walkEnd < path.asArray.length - 1) {
      walkEnd++;
      stepsToMove++;
      deviation = SKIP_VISITED;
    }
  }

  //Check again if end of path is reached
  if (deviation && stepsToMove >= remainingSteps) {
    deviation = END_OF_PATH;
  }
  return { steps: stepsToMove, deviation };
}

function avatarMoved(side, deviations = []) {
  const player = AppStore.getState().play?.current?.table.players[side];
  if (!isValueEmpty(deviations)) {
    if (deviations.includes(END_OF_PATH)) {
      ActiveGameController.completeGame(side);
    } else {
      [].concat(deviations).forEach((deviation) => {
        if (deviation === OBLIGATORY_SQUARE) {
          enqueueSnackbar(`${!ActiveGameController.isPrimaryActor() ? player.name + ' had ' : 'You had '} to stop in this square!`, { variant: 'warning', autoHideDuration: 5000 });
        } else if (deviation === SKIP_VISITED) {
          enqueueSnackbar(`${!ActiveGameController.isPrimaryActor() ? player.name + ' had ' : 'You had '} to skip visited squares!`, { variant: 'warning', autoHideDuration: 5000 });
        } else if (deviation === MOVED_BWD) {
          enqueueSnackbar(`${!ActiveGameController.isPrimaryActor() ? player.name + ' has ' : 'You have '} fallen into a decelerator (moved backwards) `, { variant: 'error', autoHideDuration: 5000 });
        } else if (deviation === MOVED_FWD) {
          enqueueSnackbar(`${!ActiveGameController.isPrimaryActor() ? player.name + ' has ' : 'You have '} fallen into an accelerator (moved forwards) `, {
            variant: 'success',
            autoHideDuration: 5000,
          });
        }
      });
      GuiService.showContent(player);
    }
  } else {
    GuiService.showContent(player);
  }
}

function submitPlayerResponse(content, square) {
  console.debug('@AGC.submitPlayerResponse: ', { content, square });
  if (!content) {
    const type = square?.type;
    GuiService.showAlert({
      title: 'Bad break...',
      message: `The ${type} contents are out of stock. You've lost your turn 😟`,
      actions: [
        {
          title: 'OK',
          color: 'primary',
          callback: () => {
            console.debug('@AGC.showAlertCallback: ', side);
            GameObserver.hideAlert();
            ActiveGameController.interactionCompleted(side);
          },
        },
      ],
    });
    GameObserver.showAlert({ title: `Lucky you...`, message: `The ${type} contents are out of stock. Opponent has lost its turn 🙂` });
  }
  const side = AppStore.getState().play?.current?.table?.activeSide;
  const playerAnswer = AppStore.getState().play?.current?.table?.lastAnswer;
  const { isCorrect, points, score } = ActiveGameController.calculatePlayerReward(side);

  const pointsLabel = AppStore.getState().play?.current?.spec?.game?.rewarding?.title;
  const { onTrue, onFalse } = AppStore.getState().play?.current?.spec?.game?.rewarding;
  AppStore.dispatch(
    submitPlayerAnswerAction({
      side,
      answerGiven: playerAnswer?.answer,
      isCorrect,
      points,
      score,
      curSquare: square.order,
      contentId: content?.id,
      randomContent: square?.randomContent,
      type: content?.type,
    })
  );
  GameObserver.submitPlayerAnswer(side, playerAnswer, isCorrect, points, score, square.order, content, content?.type, square?.randomContent);
  if (content) {
    AppStore.dispatch(setContentShownAction({ contentType: content?.type, contentId: content?.id }));

    const onFeedbackClose = () => {
      console.debug('@AGC.showFeedbackCallback: ', side, score);
      ActiveGameController.setPlayerScore(side, score);
      AppStore.dispatch(setPlayerAnswerAction({}));
      const squareCoordsKey = `${square.coords.row}_${square.coords.col}`;
      AppStore.dispatch(setSquareRandomContentAction({ squareNum: square?.order, squareCoordsKey, content: null }));
      ActiveGameController.interactionCompleted(side);
    };
    const { soundFxOn } = AppStore.getState().play?.current?.table;
    const { successFx, failureFx } = AppStore.getState().play?.current?.spec?.game;
    if (isCorrect) {
      try {
        if (soundFxOn) {
          var successFxAudio = new Audio(getSoundFxUrl(successFx, SOUND_FX_TYPE.SUCCESS) ?? DefaultSuccessFx);
          successFxAudio.volume = 0.5;
          successFxAudio.play();
        }
      } catch (err) {
        console.log('failed to play success sound', err);
      }
      GuiService.showFeedback({ variant: 'success', title: 'Bravo!!!', content: <p className='p-2'>{onTrue.replaceAll('{{points}}', points)}</p>, duration: 10 }).then(onFeedbackClose);
    } else {
      if (soundFxOn) {
        var failFxAudio = new Audio(getSoundFxUrl(failureFx, SOUND_FX_TYPE.FAILURE) ?? DefaultFailureFx);
        failFxAudio.volume = 0.5;
        failFxAudio.play();
      }
      GuiService.showFeedback({
        variant: 'error',
        title: 'Opps, that was not correct...',
        content: <p className='p-2'>{`${onFalse.replaceAll('{{points}}', points)}`}</p>,
        duration: 10,
      }).then(onFeedbackClose);
    }
  }
}
function interactionCompleted(side) {
  console.debug('@AGC.interactionCompleted: ', side);
  GameObserver.interactionCompleted(side);
  GuiService.hideContent();
  const { gameStatus } = AppStore.getState().play?.current?.table;
  AppStore.dispatch(setPlayerAnswerAction({}));
  if (gameStatus !== GAME_STATUS.COMPLETED) {
    setActivePlayer(getOtherSide(side));
  }
}

function setPlayerRoll(side, value) {
  AppStore.dispatch(setLastRollAction({ side, value }));
  GameObserver.setPlayerRoll(side, value);
}

function setPlayerScore(side, score) {
  AppStore.dispatch(setPlayerScoreAction({ side, score }));
  GameObserver.setPlayerScore(side, score);
}

function calculatePlayerReward(side) {
  const { players, lastAnswer } = AppStore.getState().play?.current?.table;
  const { path } = AppStore.getState().play?.current?.spec?.board;
  const player = players[side];
  const plSquare = player.state.curSquare;
  const plScore = player.state.score ? Number.parseInt(player.state.score) : 0;
  const squareSpec = path.asArray[plSquare];
  if (!squareSpec?.content) {
    // no random content found to be shown
    return { isCorrect: false, points: 0, score: plScore };
  }
  const { rewarding } = path.asArray[plSquare];
  if (rewarding) {
    const isCorrect = lastAnswer?.isCorrect ?? true;
    const key = `pointsOn${isCorrect.toString()[0].toUpperCase() + isCorrect.toString().substring(1)}`;
    let points = rewarding[key] ? Number.parseInt(rewarding[key]) : null;
    if (!points) {
      points = Number.parseInt(rewarding.pointsOnTrue);
    }
    const score = plScore + points;
    return { isCorrect, points, score };
  } else {
    return { isCorrect: true, points: 0, score: plScore };
  }
}

function clearPlayerRolls() {
  AppStore.dispatch(setLastRollAction({ side: 'left', value: null }));
  AppStore.dispatch(setLastRollAction({ side: 'right', value: null }));
}

function diceRolled(side, result) {
  AppStore.dispatch(setRollerActiveAction(false));
  setPlayerRoll(side, result);
  const { gameStatus, players } = AppStore.getState().play?.current?.table;

  if (gameStatus === GAME_STATUS.STARTING_UP) {
    const { left, right } = players;
    if (left.state.lastRoll && right.state.lastRoll) {
      const isaTie = left.state.lastRoll === right.state.lastRoll;
      const nextTurn = isaTie ? getOtherSide(side) : left.state.lastRoll > right.state.lastRoll ? 'left' : 'right';
      if (isaTie) {
        GuiService.showFeedback({
          variant: 'warning',
          content: `It's a tie. Try Again...`,
          duration: 0,
          callback: () => {
            clearPlayerRolls();
            setActivePlayer(nextTurn);
            AppStore.dispatch(setRollerActiveAction(true));
          },
        });
      } else {
        const player = players[nextTurn];
        GuiService.showFeedback({
          variant: 'primary',
          content: `${player.name} plays first!`,
          duration: 0,
          callback: () => {
            clearPlayerRolls();
            setActivePlayer(nextTurn);
            startGamePlay();
            AppStore.dispatch(setRollerActiveAction(true));
          },
        });
      }
    } else {
      setTimeout(() => switchPlayer(), 1000);
    }
  } else if (gameStatus === GAME_STATUS.STARTED) {
    const { steps, deviation } = calculatePlayerMove(side, result);
    avatars[side].move({ steps, deviation });
  }
}

function connectionLost() {
  AppStore.dispatch(setOnlineAppStatusAction(false));
}

function connectionRestored() {
  AppStore.dispatch(setOnlineAppStatusAction(true));
}

export const ActiveGameController = {
  setAvatars,
  enterGame,
  exitGame,
  startGameUp,
  setActivePlayer,
  switchPlayer,
  diceRolled,
  calculatePlayerMove,
  avatarMoved,
  getPlayerCurrentSquare,
  setPlayerCurrentSquare,
  setPlayerRoll,
  clearPlayerRolls,
  completeGame,
  isPrimaryActor,
  interactionCompleted,
  calculatePlayerReward,
  setPlayerScore,
  submitPlayerResponse,
  connectionLost,
  connectionRestored,
};
