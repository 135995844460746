import { Close, ExpandMore, Facebook, Instagram } from '@mui/icons-material';
import { IconButton, Stack } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

export default function Footer() {
  const isPlaying = useSelector((state) => !!state?.play?.current?.table?.tableId);
  const [isHidden, setIsHidden] = useState(false);
  const hideClick = (event) => {
    setIsHidden(true);
    event.stopPropagation();
  };
  return isPlaying || isHidden ? null : (
    <div className='z-10 flex flex-row justify-center w-full bg-emerald-600'>
      <div className='absolute left-2'>
        <IconButton type='button' sx={{ color: 'white' }} onClick={hideClick}>
          <ExpandMore />
        </IconButton>
      </div>
      <Stack direction={'row'} alignItems={'center'} gap={4}>
        <div className='flex flex-col items-center py-2'>
          <a href='https://greenethics.eu' target='_blank' rel='noreferrer'>
            <img style={{ height: 40 }} src={'/img/green-ethics-outlined.png'} alt='Green Ethics Project' />
          </a>
        </div>
        <div className='flex flex-col items-center p-2'>
          <a href='https://www.facebook.com/greenethics.eu/' target='_blank' rel='noreferrer'>
            <Facebook sx={{ color: 'white', mb: 1, fontSize: 30 }} />
          </a>
          <a href='https://www.instagram.com/greenethics.eu/' target='_blank' rel='noreferrer'>
            <Instagram sx={{ color: 'white', fontSize: 30 }} />
          </a>
        </div>
        <div className='flex flex-col items-center'>
          <img style={{ height: 50 }} src='/img/eu-cofunded-outlined.png' alt='EU' />
        </div>
      </Stack>
    </div>
  );
}
