import { ApiClient } from './utils/ApiClient';
import { CustomResponseError } from '../../utils/Utils';

export const ContentDao = {
  async fetchContent(contentId, gameId) {
    try {
      const { data } = await ApiClient.get(`/play/games/${gameId ?? 'any'}/contents/${contentId}`);
      return data;
    } catch (error) {
      throw CustomResponseError(error);
    }
  },
};
