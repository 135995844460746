import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { truncate } from 'lodash';

export default function TopBreadcrumb({ routes, route, currentGame, currentSlug }) {
  return (
    <div role='presentation'>
      <Breadcrumbs separator='|' sx={{ color: 'white' }} aria-label='breadcrumb'>
        {routes
          .filter((r) => r.value !== currentGame?.slug)
          .map((r) => {
            const isActive = [route, currentSlug, currentGame?.slug].includes(r.value);
            return (
              <div key={r.value}>
                <Link sx={{ textTransform: 'uppercase', fontWeight: 'bold' }} underline='hover' color={isActive ? 'primary.extraLight' : 'primary.dark'} href={`/${r.value}`}>
                  {r.label}
                </Link>
                {isActive && currentGame && (
                  <>
                    <span className={`text-white`}>{' / '}</span>
                    <span className={`text-white`}>{truncate(currentGame.title, { length: 18 })}</span>
                  </>
                )}
              </div>
            );
          })}
      </Breadcrumbs>
    </div>
  );
}
