import { Button } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { closeSnackbar, SnackbarProvider } from 'notistack';
import { PALLETE } from '../config/constants';

//const theme = createTheme();
const theme = createTheme({
  typography: {
    fontFamily: '"Roboto", "Arial", sans-serif',
  },
  palette: {
    neutral: { light: '#aaa', main: '#363636', dark: '#1c1c1c' },
    primary: PALLETE.EMERLAND,
    secondary: PALLETE.PURPLE,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 500,
      md: 640,
      lg: 800,
      xl: 1024,
    },
  },
});

export default function AppThemeProvider({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        style={{ padding: 10, fontSize: 16 }}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        autoHideDuration={3000}
        action={(key) => (
          <Button onClick={() => closeSnackbar(key)} style={{ color: '#fff', fontSize: '20px' }}>
            ✖
          </Button>
        )}
      />
      {children}
    </ThemeProvider>
  );
}
