/* eslint-disable no-undef */
import { Add, Check, Close, DoDisturbOffOutlined, DoDisturbOnOutlined, RouteOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { cloneDeep, isEmpty, uniqueId } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactGridLayout from 'react-grid-layout';
import { useSelector } from 'react-redux';
//import '../../../../node_modules/react-grid-layout/css/styles.css';
import { useParams } from 'react-router-dom';
import { BASE_URL } from '../../../config/config';
import useGameHook from '../../../hooks/make/useGameHook';
import useGameMetrics from '../../../hooks/make/useGameMetrics';
import useMediaHook from '../../../hooks/useMediaHook';
import useScript from '../../../hooks/useScript';
import '../../../react-grid-layout.css';
import { arrayToMap, checkRandomContetnsOfPath, getCoordsForNewSquare, getOrderedSquareIds, isPathLayoutValid } from '../../../utils/GameEditUtils';
import BoardSquare from './BoardSquare';
import SquareEditor from './SquareEditor';
import { GuiService } from '../../../services/GuiService';
import { enqueueSnackbar } from 'notistack';
import { FinalSquareTypes } from '@grethics/commons';

export default function BoardEditor({ board, path }) {
  const { loaded: ldrLineLoaded } = useScript(BASE_URL + '/js/leader-line.min.js');
  const { slug } = useParams();
  const { media: boardBg } = useMediaHook(board?.bgMediaId);
  const [localPath, setLocalPath] = useState(path?.asArray ?? []);
  const [deletedSquares, setDeletedSquares] = useState([]);
  const [onAddMode, setOnAddMode] = useState(false);
  const [onDelMode, setOnDelMode] = useState(false);
  const [isDraggable, setDraggable] = useState(false);
  const [prevLayout, setPrevLayout] = useState(null);
  const [boardLayout, setBoardLayout] = useState([]);
  const { updateGamePath } = useGameHook(slug);
  const outer = document.getElementById('edit-outer');
  const canvas = document.getElementById('edit-canvas');
  const squareEditorRef = useRef();
  const { CellSize, SquareSize, BoardSize } = useGameMetrics(outer, canvas, board?.size ?? 10, false);
  const allContents = useSelector((state) => state.make.current.contents);
  /* Create Layout from path */
  useEffect(() => {
    if (path) {
      const layout = path.asArray.map((square) => ({ i: `sq${square.order}`, x: square.coords.col, y: square.coords.row, w: 1, h: 1 }));
      setPrevLayout(layout);
      setBoardLayout(layout);
      setLocalPath(cloneDeep(path.asArray));
    }
  }, [path]);

  /* Draw AutoTransition arrows (with LeaderLine) */
  useEffect(() => {
    if (localPath && ldrLineLoaded) {
      const lines = [];
      localPath
        .filter((sq) => sq.transition)
        .forEach((sq) => {
          const start = document.getElementById(`sq${sq.order}`);
          const end = document.getElementById(`sq${sq.transition.to}`);
          const color = sq.transition.color ? sq.transition.color : sq.transition.type === 'fwd' ? 'green' : 'red';
          if (start && end) {
            lines.push(
              new LeaderLine(start, end, {
                size: 8,
                color,
                startSocket: sq.transition.takeoff ?? 'auto',
                endSocket: sq.transition.landing ?? 'auto',
                startPlug: 'disc',
                dash: sq.transition.line === 'dash' ? { animation: !!sq.transition.animated } : false,
              })
            );
          }
        });
      return () => lines.forEach((line) => line.remove());
    }
  }, [localPath, ldrLineLoaded]);

  const isPathValid = useMemo(() => {
    return isPathLayoutValid(boardLayout, localPath)?.isValid === true;
  }, [boardLayout, localPath]);

  if (isEmpty(board)) {
    return <div className='flex flex-1 h-full bg-slate-100'>No Board Yet</div>;
  }

  const isEditable = !isDraggable;

  const boardBgUrl = boardBg?.url;

  const onSquareEdit = (square) => {
    if (squareEditorRef.current) {
      squareEditorRef.current.show(square);
    }
  };

  const onSquareEdited = async (square) => {
    console.log({ square });
    square.updated = true;
    const errors = checkRandomContetnsOfPath(Object.values(allContents), localPath.filter((sq) => sq.id !== square.id && !FinalSquareTypes.includes(sq.type)).concat([square]));
    if (!isEmpty(errors)) {
      Object.keys(errors).forEach((key) => {
        enqueueSnackbar(errors[key], { variant: 'warning', autoHideDuration: 10000 });
      });
      /* GuiService.showAlert({
        title: 'Game Contents Warning',
        message: (
          <p>
            <ul>
              {Object.keys(errors).map((key, idx) => (
                <li className='text-red-600' key={'err' + idx}>
                  {errors[key]}
                </li>
              ))}
            </ul>
          </p>
        ),
        actions: [
          {
            title: 'OK',
            color: 'primary',
          },
        ],
      }); */
    }
    return updateGamePath(slug, [square]);
  };

  const addSquare = () => {
    const coordUpdates = getCoordsForNewSquare(boardLayout, board.size);
    console.log('Adding a new square at', coordUpdates);
    const newId = uniqueId('new_');
    let newPath = cloneDeep(localPath);
    const final = newPath.pop();

    const clone = cloneDeep(newPath[newPath.length - 1]);
    clone.id = newId;
    clone.order = clone.order + 1;
    clone.coords.col = coordUpdates.new.x;
    clone.coords.row = coordUpdates.new.y;
    clone.bgMediaId = null;
    newPath.push(clone);

    final.order = final.order + 2;
    final.coords.col = coordUpdates.final.x;
    final.coords.row = coordUpdates.final.y;
    newPath.push(final);

    setLocalPath(newPath);
    const newLayout = newPath.map((square) => ({ i: `sq${square.order}`, x: square.coords.col, y: square.coords.row, w: 1, h: 1 }));
    setBoardLayout(newLayout);
  };

  const deleteSquare = (square) => {
    console.log('Deleting square', square.id);
    square.toDelete = true;
    setDeletedSquares(deletedSquares.concat([square]));
    setBoardLayout(boardLayout.filter((sq) => sq.i !== `sq${square.order}`));
    setLocalPath(localPath.filter((sq) => sq.id !== square.id));
  };

  const onSquareMoved = (layout, oldItem, newItem, placeHolder, e, elem) => {
    console.log(newItem.i + ' moved');
  };

  const onLayoutChange = (layout) => {
    setBoardLayout(layout);
  };

  const cancelLayoutChanges = () => {
    setBoardLayout(prevLayout);
    setLocalPath(path.asArray);
    setDraggable(false);
    setOnDelMode(false);
  };

  const applyLayoutChanges = () => {
    const startSquare = localPath.find((sq) => sq.type === 'START');
    const orderedIds = getOrderedSquareIds(boardLayout, `sq${startSquare.order}`);
    console.log(JSON.stringify(orderedIds));
    const boxMap = arrayToMap(boardLayout, 'i');
    const squareMap = arrayToMap(localPath, 'order');
    const newBoardPath = [];
    orderedIds.forEach((sqKey, idx) => {
      const key = sqKey.substring(2);
      if (squareMap[key]) {
        const curSquare = squareMap[key];
        if (('' + curSquare.id).startsWith('new_')) {
          delete curSquare.id;
          delete curSquare.bgMedia;
          delete curSquare.updated;
        }
        if (curSquare.order !== idx || curSquare.coords.col !== boxMap[sqKey].x || curSquare.coords.row !== boxMap[sqKey].y) {
          curSquare.order = idx;
          curSquare.coords.col = boxMap[sqKey].x;
          curSquare.coords.row = boxMap[sqKey].y;
          curSquare.updated = true;
        }
        newBoardPath.push(curSquare);
      }
    });
    //Send Patch Request
    updateGamePath(slug, newBoardPath.concat(deletedSquares))
      .then(() => {
        setDraggable(false);
        setOnDelMode(false);
      })
      .catch((err) => {});
  };

  return (
    <div
      id='edit-outer'
      style={{
        position: 'relative',
        height: '100%',
        width: '100%',
      }}>
      <SquareEditor ref={squareEditorRef} onSave={onSquareEdited} />
      <div id='edit-canvas'>
        {BoardSize !== 500 && (
          <ReactGridLayout
            //isResizable={false}
            isDraggable={isDraggable}
            compactType={null}
            preventCollision={true}
            className='layout'
            isBounded={true}
            isResizable={false}
            layout={boardLayout}
            onLayoutChange={onLayoutChange}
            margin={[0, 0]}
            onDragStop={onSquareMoved}
            cols={board.size}
            style={{ height: BoardSize, width: BoardSize, backgroundImage: `url(${boardBgUrl})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', border: '1px solid black' }}
            rowHeight={CellSize}
            width={BoardSize}
            containerPadding={[0, 0]}>
            {localPath.map((square) => (
              <div key={'sq' + square.order} className='flex flex-col items-center justify-center bg-white'>
                <BoardSquare board={board} square={square} SquareSize={SquareSize} isDraggable={isDraggable} onDelete={onDelMode ? deleteSquare : null} onEdit={isEditable ? onSquareEdit : null} />
              </div>
            ))}
          </ReactGridLayout>
        )}
        {!isDraggable && (
          <IconButton style={{ position: 'absolute', top: 5, right: 5, backgroundColor: 'white', zIndex: 10 }} onClick={() => setDraggable(true)}>
            <RouteOutlined fontSize='large' color='primary' />
          </IconButton>
        )}
        {isDraggable && (
          <IconButton style={{ position: 'absolute', top: 5, left: 5, backgroundColor: 'white', zIndex: 10 }} onClick={cancelLayoutChanges}>
            <Close fontSize='large' color='error' style={{}} />
          </IconButton>
        )}
        {isDraggable && (
          <IconButton style={{ position: 'absolute', top: 5, right: 65, backgroundColor: 'white', zIndex: 10 }} onClick={addSquare}>
            <Add fontSize='large' color='primary' style={{}} />
          </IconButton>
        )}
        {isDraggable && (
          <IconButton style={{ position: 'absolute', top: 5, right: 125, backgroundColor: 'white', zIndex: 10 }} onClick={() => setOnDelMode(!onDelMode)}>
            {!onDelMode && <DoDisturbOnOutlined fontSize='large' color={'error'} style={{}} />}
            {onDelMode && <DoDisturbOffOutlined fontSize='large' color={'primary'} style={{}} />}
          </IconButton>
        )}
        {isDraggable && (
          <IconButton style={{ position: 'absolute', top: 5, right: 5, backgroundColor: 'white', zIndex: 10 }} onClick={applyLayoutChanges} disabled={!isPathValid}>
            <Check fontSize='large' color={isPathValid ? 'success' : 'gray'} style={{}} />
          </IconButton>
        )}
      </div>
    </div>
  );
}
