import { ContentType } from '@grethics/commons';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActiveGameController } from '../../../control/play/ActiveGameController';
import { GameObserver } from '../../../control/play/GameObserver';
import useContentHook from '../../../hooks/play/useContentHook';
import { setPlayerAnswerAction } from '../../../model/store/slices/PlaySlice';
import { areEqual } from '../../../utils/Utils';
import DisposableDialogTitle from '../common/DisposableDialogTitle';
import InfoContent from './InfoContent';
import QuizContent from './QuizContent';
const visitedContents = { [ContentType.FACT]: [], [ContentType.DID_YOU_KNOW]: [], [ContentType.STORY]: [] };

const ContentModal = React.forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const { game, board, players } = useSelector((state) => state.play.current.spec);
  const allContents = game?.allContents;
  const shownContents = useSelector((state) => state.play.current.table.shownContents);
  const activeSide = useSelector((state) => state.play.current.table.activeSide);
  const isActing = ActiveGameController.isPrimaryActor();
  const [player, setPlayer] = useState(null);
  const [square, setSquare] = useState(null);
  const [closable, setClosable] = useState(false);

  const dispatch = useDispatch();

  const { content, error, loading } = useContentHook(square, allContents, shownContents);

  /* effect to set player, square and content.... */
  useEffect(() => {
    if (player) {
      const curSquare = player?.state?.curSquare;
      const square = board.path.asArray[curSquare];
      const { content, type } = square;
      console.debug(`@CM: Player is ${player?.id} with current quare ${curSquare} which is of type ${type} and content ${content?.id}`);
      setSquare(square);
      if (type !== ContentType.QUIZ || !content) {
        setClosable(true);
      }
    } else {
      setSquare(null);
      setClosable(false);      
    }
  }, [player, board]);

  /* effect to handle show/hide of contentModal */
  useEffect(() => {
    if (content) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [content]);

  const onQuizAnswered = useCallback(
    (answer) => {
      console.log(`Current Answer: `, JSON.stringify({ answer }));
      let isCorrect = true;
      const { correctAnswer } = content?.body;
      if (correctAnswer) {
        isCorrect = areEqual(answer, correctAnswer);
        console.log('isCorrect: ', isCorrect);
      }
      dispatch(setPlayerAnswerAction({ side: activeSide, answer, isCorrect }));
      GameObserver.setPlayerAnswer({ side: activeSide, answer, isCorrect });
      setClosable(!isEmpty(answer));
    },
    [activeSide, content, dispatch]
  );

  const handleClose = async (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    setPlayer(null);
    console.debug('@CM.hide: ', `player: ${player?.name} ==> undefined`);
  };

  useImperativeHandle(ref, () => ({
    show(newPlayer) {
      console.debug('@CM.show: ', { currentPlayer: player?.name, newPlayer: newPlayer?.name });
      setPlayer(newPlayer);
    },
    hide() {
      handleClose();
    },
  }));

  return (
    <>
      {/* <button
        className='w-full bg-red-700 text-white'
        onClick={() => {
          setPlayer(players[activeSide]);
          setSquare(board.path.asArray[21]);
          setOpen(true);
        }}>
        Open Modal
      </button> */}
      <div>
        <Dialog disableEscapeKeyDown maxWidth={'md'} onClose={handleClose} aria-labelledby='customized-dialog-title' open={open} sx={{ mx: 'auto' }}>
          <DisposableDialogTitle id='customized-dialog-title'>
            {'[' + player?.state?.curSquare + ']'}: {content?.title ?? ''} {true && <>{` (${player?.name} plays)`}</>}
          </DisposableDialogTitle>
          <DialogContent dividers sx={{}}>
            {loading && <div>Loading...</div>}
            {[ContentType.FACT, ContentType.DID_YOU_KNOW, ContentType.STORY].includes(square?.type) && (
              <InfoContent
                content={content?.body}
                onContentShown={() => {
                  setClosable(true);
                }}
              />
            )}
            {square?.type === ContentType.QUIZ && <QuizContent content={content} onQuizAnswered={onQuizAnswered} />}
          </DialogContent>
          {isActing && (
            <DialogActions sx={{ justifyContent: 'end', bgcolor: 'primary.light' }}>
              <Button
                variant='contained'
                autoFocus
                onClick={() => {
                  ActiveGameController.submitPlayerResponse(content, square);
                }}
                disabled={!closable}>
                OK
              </Button>
            </DialogActions>
          )}
        </Dialog>
      </div>
    </>
  );
});

export default ContentModal;
