import { BoardSquare, SquareType, FinalSquareTypes, ContentType, ContentTypes } from '@grethics/commons';
import { sq } from 'date-fns/locale';

type LayoutSquare = { i: string; x: number; y: number; w: number; h: number; type: string };

export const getNeighbors = (square: LayoutSquare, layout: LayoutSquare[]): LayoutSquare[] => {
  let neighbors = [];
  const { x, y } = square;
  neighbors = layout.filter((sq) => (sq.x === x - 1 && sq.y === y) || (sq.x === x + 1 && sq.y === y) || (sq.y === y - 1 && sq.x === x) || (sq.y === y + 1 && sq.x === x));
  return neighbors;
};

export const isPathLayoutValid = (layout: LayoutSquare[], path: any[]): { isValid: boolean; message?: string } => {
  try {
    //const pathMap = arrayToMap(path ?? [], 'id');
    /* Check path continuity */
    layout.forEach((box, idx) => {
      const sq = path[idx];
      const numOfNeighbors = getNeighbors(box, layout).length;
      if (FinalSquareTypes.includes(sq.type)) {
        if (numOfNeighbors !== 1) {
          throw new Error(`Square ${idx} is not valid`);
        }
      } else if (numOfNeighbors !== 2) {
        throw new Error(`Square ${idx} is not valid`);
      }
    });
  } catch (err: any) {
    return { isValid: false, message: err.message };
  }
  return { isValid: true };
};

export const isPathSectionsValid = (path: BoardSquare[]): { isValid: boolean; message?: string } => {
  try {
    /* Check path continuity */
    if (!Array.isArray(path)) {
      return { isValid: true };
    }
    type SectionInfo = { [key: string]: { from: number; to?: number } };
    path.reduce((info: SectionInfo, square: BoardSquare, idx: number): SectionInfo => {
      if (!square.sectionId) {
        return info;
      }
      if (info[square.sectionId]) {
        const secInfo = info[square.sectionId];
        if (secInfo.to && secInfo.to < idx - 1) {
          throw new Error(`Section of Square ${idx} is wrong.`);
        } else {
          secInfo.to = idx;
        }
        return { ...info, [square.sectionId]: secInfo };
      } else {
        return { ...info, [square.sectionId]: { from: idx, to: idx } };
      }
    }, {});
  } catch (err: any) {
    return { isValid: false, message: err.message };
  }
  return { isValid: true };
};

export const getCoordsForNewSquare = (layout: LayoutSquare[], boardSise: number): { new: { x: number; y: number }; final?: { x: number; y: number } } => {
  let shift = 'NONE';
  if (layout.length >= 2) {
    const preFinalBox = layout[layout.length - 2];
    const finalBox = layout[layout.length - 1];
    const { x, y } = finalBox;
    const final = { x, y };
    if (preFinalBox.x === finalBox.x) {
      // Vertical
      if (preFinalBox.y < finalBox.y && finalBox.y !== boardSise - 1) {
        shift = 'DOWN';
        final.y++;
      } else if (preFinalBox.y > finalBox.y && finalBox.y !== 0) {
        shift = 'UP';
        final.y--;
      } else {
        shift = finalBox.x === boardSise - 1 ? 'LEFT' : 'RIGHT';
        if (x === boardSise - 1) {
          final.x--;
        } else {
          final.x++;
        }
      }
    } else if (preFinalBox.y === finalBox.y) {
      // Horizontal
      if (preFinalBox.x < finalBox.x && finalBox.x !== boardSise - 1) {
        shift = 'RIGHT';
        final.x++;
      } else if (preFinalBox.x > finalBox.x && finalBox.x !== 0) {
        shift = 'LEFT';
        final.x--;
      } else {
        shift = finalBox.y === boardSise - 1 ? 'UP' : 'DOWN';
        if (y === boardSise - 1) {
          final.y--;
        } else {
          final.y++;
        }
      }
    }
    return { new: { x, y }, final };
  }
  return { new: { x: boardSise / 2, y: boardSise / 2 } };
};

/* export const getCoordsForNewSquare = (layout: LayoutSquare[], boardSise: number): { new: { x: number; y: number }; final?: { x: number; y: number } } => {
  let shift = 'NONE';
  if (layout.length > 0) {
    const preFinalBox = layout[layout.length - 2];
    const finalBox = layout[layout.length - 1];
    const { x, y } = finalBox;
    const final = { x, y };
    const neighbors = getNeighbors(finalBox, layout);
    if (preFinalBox.x === finalBox.x) {
      // Vertical
      if (preFinalBox.y < finalBox.y && finalBox.y !== boardSise - 1) {
        shift = 'DOWN';
        final.y++;
      } else if (preFinalBox.y > finalBox.y && finalBox.y !== 0) {
        shift = 'UP';
        final.y--;
      } else {
        shift = finalBox.x === boardSise - 1 ? 'LEFT' : 'RIGHT';
        if (x === boardSise - 1) {
          final.x--;
        } else {
          final.x++;
        }
      }
    } else if (preFinalBox.y === finalBox.y) {
      // Horizontal
      if (preFinalBox.x < finalBox.x && finalBox.x !== boardSise - 1) {
        shift = 'RIGHT';
        final.x++;
      } else if (preFinalBox.x > finalBox.x && finalBox.x !== 0) {
        shift = 'LEFT';
        final.x--;
      } else {
        shift = finalBox.y === boardSise - 1 ? 'UP' : 'DOWN';
        if (y === boardSise - 1) {
          final.y--;
        } else {
          final.y++;
        }
      }
    }
    return { new: { x, y }, final };
  }
  return { new: { x: boardSise / 2, y: boardSise / 2 } };
}; */

const getNextSquareInLayout = (layout: LayoutSquare[], current: LayoutSquare, visited: string[] = []): LayoutSquare | undefined => {
  const { x, y } = current;
  const next = layout.find((sq) => {
    return ((Math.abs(sq.x - x) === 1 && sq.y === y) || (sq.x === x && Math.abs(sq.y - y) === 1)) && !visited.includes(sq.i);
  });
  return next;
};

export const getOrderedSquareIds = (layout: LayoutSquare[], startSquareId: string): string[] => {
  const list = [];
  console.log(JSON.stringify(layout));
  let current = layout.find((sq) => sq.i === startSquareId);
  if (current) {
    list.push(current.i);
    let next = getNextSquareInLayout(layout, current, list);
    while (next) {
      current = next;
      next = getNextSquareInLayout(layout, current, list);
      list.push(current.i);
    }
  }
  console.log(JSON.stringify(list));
  return list;
};

export const arrayToMap = (array: any[], objKey: string): any => {
  const getObjKeyValue = (item: any, keys: string[]): any => {
    if (keys.length === 1) {
      return item[keys[0]];
    }
    const curKey = keys[0];
    keys.shift();
    return getObjKeyValue(item[curKey], keys);
  };
  if (array) {
    return array.reduce((obj, item) => {
      const key = getObjKeyValue(item, objKey.split('.'));
      obj[key] = item;
      return obj;
    }, {});
  }
  return {};
};

export const checkRandomContetnsOfPath = (allContents: { id: number; type: ContentType }[], path: BoardSquare[]) => {
  let errors: any = {};
  const allContentsMap: any = {};
  ContentTypes.forEach((type) => {
    allContentsMap[type] = [];
  });
  allContents.forEach((content) => {
    allContentsMap[content.type].push(content.id);
  });
  const randomSquaresMap: any = {};
  const usedContentsMap: any = {};
  ContentTypes.forEach((type) => {
    randomSquaresMap[type] = [];
    usedContentsMap[type] = [];
  });
  path.forEach((sq) => {
    if (sq.randomContent) {
      randomSquaresMap[sq.type].push(sq.order);
    } else {
      usedContentsMap[sq.type].push(sq.contentId);
    }
  });
  ContentTypes.forEach((type) => {
    const randomSquaresOfType = randomSquaresMap[type].length;
    const usedContentsOfType = usedContentsMap[type].length;
    const allContentsOfType = allContentsMap[type].length;
    if (randomSquaresOfType > allContentsOfType - usedContentsOfType) {
      const missing = randomSquaresOfType - (allContentsOfType - usedContentsOfType);
      errors[type] = `${randomSquaresOfType} random squares of type ${type}, but only ${
        allContentsOfType - usedContentsOfType
      } contents of this type available. ${missing} squares may show already shown content.`;
    }
  });

  return errors;
};

export const checkContentDublicates = (path: BoardSquare[]) => {
  let errors: any = {};

  return errors;
};
